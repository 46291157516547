import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";
import IMG6 from "../../assets/portfolio6.jpg";

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="mobile portfolio home website" />
          </div>
          <h3>Home page-Mobile view</h3>
          <div className="portfolio__item-cta">
            {/* <a href="https://github.com" className="btn">
              Github
            </a> */}
            <a
              href="https://mix225africa.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="mobile portfolio artist website" />
          </div>
          <h3>Artist page -Mobile view</h3>
          <div className="portfolio__item-cta">
            {/* <a href="https://github.com" className="btn">
              Github
            </a> */}
            <a
              href="https://mix225africa.com/artists.html"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG5} alt="mobile portfolio player website" />
          </div>
          <h3>Music player-Mobile view</h3>
          <div className="portfolio__item-cta">
            {/* <a href="https://github.com" className="btn">
              Github
            </a> */}
            <a
              href="https://mix225africa.com/player-23.html"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image portfolio__item-img">
            <img src={IMG4} alt="Instagram beach illustration" />
          </div>
          <h3>Social media illustration </h3>
          <div className="portfolio__item-cta">
            {/* <a href="https://github.com" className="btn">
              View more
            </a> */}
            <a
              href="https://www.instagram.com/yannthedonmade/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image portfolio__item-img">
            <img src={IMG6} alt="Instagram character illustration" />
          </div>
          <h3>Social media illustration</h3>
          <div className="portfolio__item-cta">
            <a href="https://www.instagram.com/yannthedonmade/" className="btn">
              View Live
            </a>
            {/* <a
              href="https://dribble.com"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a> */}
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image portfolio__item-img">
            <img src={IMG1} alt="Instagram scene illustration" />
          </div>
          <h3>Social media illustation</h3>
          <div className="portfolio__item-cta">
            {/* <a href="https://www.instagram.com/yannthedonmade/" className="btn">
              Github
            </a> */}
            <a
              href="https://www.instagram.com/yannthedonmade/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              View Live
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default portfolio;
