import React from "react";
// import "animate.css";
import "./about.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const about = () => {
  return (
    <section id="about">
      <h5>Get To know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Yann Amon" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+ years</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Graphic Design Projects</h5>
              <small>4</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Web Development Projects</h5>
              <small>2 Completed Projects</small>
            </article>
          </div>
          <p>
            Let me introduce myself, I'm Yann Amon, a Front-End Developer and
            Grahpic Design. I'm a NAIT graduate with a diploma in Digital Media
            and Web Development. I have a passion for web development and strive
            to create responsive and accessible designs. I'm always looking for
            new challenges and opportunities to learn new skills. m
            classname="animate__animated animate__headShake" a team player and I
            love to work with people who share the same passion as me. I'm a
            very creative person and I enjoy to create new user experiences.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
          <div />
        </div>
      </div>
    </section>
  );
};

export default about;
