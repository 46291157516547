import React from "react";
import "./service.css";
import { BiCheck } from "react-icons/bi";
const service = () => {
  return (
    <section id="service">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Logo Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Branding material</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website low fidelity wireframe</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website high fidelity wireframe</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Moodboard Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User experience analysis</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wordpress Website with custom post-type</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website revamp</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website animation</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Website with custom admin dashboard</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>SEO optimization</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Blog post writing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Biography writing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Social medio captsion writing</p>
            </li>
            {/* <li>
                <BiCheck className='service__list-icon' />
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </li> */}
          </ul>
        </article>
      </div>
    </section>
  );
};

export default service;
