import React from "react";
import "./footer.css";
// import { FiFacebook } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
// import { FiTwitter } from "react-icons/fi";

const footer = () => {
  return (
    <footer>
      <a href="#about" className="footer__logo">
        Yann Amon
      </a>
      <ul className="permalinks">
        <li>
          <a href="#about">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#service">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        {/* <li>
          <a href="#testimonial">Testiminonials</a>
        </li> */}
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="footer__socials">
        {/* <a href="https://facebook.com">
          <FiFacebook />
        </a> */}
        <a href="https://instagram.com/yannthedonmande">
          <FiInstagram />
        </a>
        {/* <a href="https://twitter.com">
          <FiTwitter />
        </a> */}
      </div>
      <div className="footer__copyright">
        <small>&copy; Yann Amon. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default footer;
